html {
  scroll-behavior: smooth;
}

body {
  font-family: "Nunito", sans-serif;
}

.container {
  max-width: 576px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
}

.content {
  flex: 1;
}

.sticky-button {
  position: sticky;
  bottom: 24px;
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  z-index: 99;
}

.text-sm {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #1f1f1f;
}

.text-justify {
  text-align: justify;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-d {
  margin-bottom: 16px;
}

.title-4 {
  font-size: 17px;
  font-weight: 700;
  line-height: 23.19px;
  text-align: left;
  margin-bottom: 8px;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Khusus untuk input dan button */
input:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Untuk input group dari Bootstrap */
.input-group .form-control:focus,
.input-group .input-group-text:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: inherit;
}

/* Logo */
.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 16px;
  font-size: 20px;
  font-weight: 700;
  margin: 24px 0;
}
/* End Logo */

/* Header */
.header {
  width: 100%;
}

.header .title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30.01px;
  text-align: left;
  margin-bottom: 8px;
}

.header .text {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.88px;
  text-align: left;
  color: #525252;
}

.header-large {
  width: 100%;
  margin-top: 16px;
}

.divider {
  border-bottom: 1px solid #ddd;
}

.header-large .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 46.38px;
}

.header-large p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.72px;
  color: #525252;
}
/* End Header */

/* Point Input */
.point-input-container {
  padding: 16px 8px;
  border-radius: 8px;
  background: #fafafa;
  position: relative;
}

.point-input-container .timeline .timeline-item {
  display: flex;
  flex-direction: column;
}

.point-input-container .timeline .timeline-item:not(:last-child)::after {
  content: "";
  display: block;
  border: 1px solid #ebebeb;
  width: 100%;
  margin-top: 4px;
}

.search-container {
  width: 100%;
  /* position: relative; */
}

.search-container .form-control {
  background-color: transparent;
  border: none;
  padding: 0;
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.88px;
  text-align: left;
  height: 20px;
  color: #1f1f1f;
  /* border: 1px solid red; */
}

.search-results {
  position: absolute;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0px 12px 37px 0px #41535940;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  padding: 16px;
}

.search-results .list-group-item {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border: none;

  /* height: 41px; */
}

.search-results.list-group.loading {
  font-size: 14px;
  color: #525252;
  font-weight: 400;
}

.search-results.list-group.error {
  font-size: 14px;
  color: #9e9e9e;
  font-weight: 400;
}

.search-results .list-group-item:hover {
  background: #f0f0f0;
}

.search-results .icon-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 14.2px;
  text-align: center;
  color: #9e9e9e;
}
.search-results .place-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-x: hidden;
}
.search-results .place-wrapper .name {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.88px;
}

.search-results .place-wrapper .detail {
  font-size: 12px;
  font-weight: 400;
  line-height: 17.04px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #525252;
}

.loading,
.error {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}

.loading {
  color: #1f1f1f;
}

.error {
  color: #dc3545;
}

/* End Point Input */
.react-horizontal-scrolling-menu--scroll-container {
  padding-top: 30px;
}

.transport-option {
  background-color: #fafafa;
  margin-right: 16px;
  width: 120px;
  height: 115px;
  position: relative; /* Pastikan elemen ini memiliki posisi relatif */
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transisi untuk hover yang lebih halus */
}

.transport-option .icon-wrapper {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

.transport-option .icon-wrapper img {
  display: block;
  width: 100px;
  height: 100px;
  pointer-events: none; /* Gambar tidak menghalangi hover */
}

.transport-option .content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.transport-option .content .title {
  font-size: 15px;
  font-weight: 700;
  line-height: 20.46px;
}

.transport-option .content .info {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  color: #165a43;
  visibility: hidden;
}

.transport-option.active {
  background-color: #def7ee;
}

.transport-option.active .content .title {
  color: #165a43;
}

.transport-option.active .content .info {
  visibility: visible;
}

/* End transport-option */

.info-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-radius: 4px;
  background-color: #f5f6f6;
  align-items: start;
}

.info-container .icon-wrapper {
  cursor: pointer;
}

.info-container a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  text-decoration: none;
  color: #1f1f1f;
  height: 24px;
}

.info-container .info-detail {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #1f1f1f;
}

.info-container .collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.8s ease;
}

.info-container .collapse.show {
  height: auto;
}

/* Button */
.btn.btn-confirm,
.btn.btn-confirm:hover,
.btn.btn-confirm:active {
  padding: 16px;
  border-radius: 8px;
  color: #fafafa;
  background-color: #1f8562;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.82px;
  text-align: center;
  border: none;
  bottom: 24px;
}

.btn.btn-confirm:disabled {
  background-color: #ebebeb;
  color: #b8b8b8;
  border: none;
  cursor: not-allowed;
}

.btn.btn-outline-confirm {
  padding: 16px;
  border-radius: 8px;
  color: #1f8562;
  border: 1px solid #1f8562;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.82px;
  text-align: center;
  background-color: white;
  z-index: 999;
}

.btn.btn-outline-confirm:hover,
.btn.btn-outline-confirm:active {
  color: #fafafa;
  background-color: #1f8562;
}

.fixed-button {
  position: fixed;
  bottom: 24px;
  left: 0;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
}

/* End Button */

/* Loading */
.loading-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.loading-container p {
  font-size: 17px;
  font-weight: 700;
  line-height: 23.19px;
  text-align: center;
}

.loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots span {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: #165a43;
  border-radius: 50%;
  margin: 0 10px;
  animation: bounce 1.6s infinite ease-in-out;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-20px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}
.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}
.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}
.loading-dots span:nth-child(4) {
  animation-delay: 0.6s;
}
.loading-dots span:nth-child(5) {
  animation-delay: 0.8s;
}
/* End Loading */

/* Card Summary */
.card-summary {
  min-height: 180px;
  background: linear-gradient(106.75deg, #fafafa 38.43%, #e4e7e9 80.83%);
  border: none;
  border-radius: 8px;
  color: #525252; /* Warna teks default */
  margin: 16px 0;
  position: relative;
}

.card-summary.active {
  background: linear-gradient(115.65deg, #1f8562 -22.3%, #0c3125 197.83%);
  color: #fff; /* Warna teks saat aktif */
}

.card-summary .icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.card-summary .summary-title {
  font-size: 17px;
  font-weight: 700;
  line-height: 23.19px;
  text-align: left;
  color: inherit; /* Pewarisan warna dari elemen induk */
}

.card-summary .content-wrapper {
  background-color: transparent;
  padding: 16px;
  position: absolute;
  z-index: 1;
}

.card-summary .info-item .item-label {
  font-size: 12px;
  font-weight: 400;
  line-height: 17.04px;
  display: flex;
  align-items: center;
  color: inherit; /* Pewarisan warna dari elemen induk */
}

.card-summary .info-item .item-label svg {
  margin-right: 5px;
  stroke: #525252; /* Pewarisan warna dari elemen induk */
}

.card-summary.active .info-item .item-label svg {
  stroke: #fff; /* Pewarisan warna dari elemen induk */
}

.card-summary.active .icon-wrapper img {
  opacity: 0.3;
}

.card-summary .info-item .item-value {
  display: flex;
  align-items: flex-end;
  margin-top: 5px;
}

.card-summary .info-item .item-value h4 {
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  margin: 0;
  color: inherit; /* Pewarisan warna dari elemen induk */
}

.card-summary .info-item .unit {
  font-size: 12px;
  font-weight: 400;
  line-height: 17.04px;
}

/* End Card Summary */

/* trip-wrapper */
.trip-wrapper {
  display: flex;
  flex-direction: row;
}

.trip-wrapper .trip-icon {
  width: 40px;
  height: 40px;
}

/* timeline */
.timeline {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  margin-left: 10px;
}

.timeline-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10px;
  padding: 0 25px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21.3px;
  text-align: left;
  padding-bottom: 10px;
  height: 50px;
}

.timeline-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  z-index: 1;
}

.timeline-item:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 20px;
  left: 0;
  top: 16px;
  background-image: radial-gradient(
    circle at 2px,
    #ccc 1px,
    rgba(255, 255, 255, 0) 2px
  );
  background-position: top, right, bottom, left;
  background-size: 8px 5px;
  background-repeat: repeat-y;
}

.timeline-item:last-child::before {
  display: none;
}

/* end trip-wrapper */

/* Error Container */
.error-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.error-container img {
  width: 200px;
  height: 200px;
}

.error-container .error-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30.01px;
  text-align: center;
}

.error-container .error-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.72px;
  text-align: center;
}
/* Error Container */

/* Banner Header */
.banner-header {
  max-width: 576px;
  margin: auto;
  width: 100%;
  margin-bottom: 8px;
}

.banner-header img {
  width: 100%;
}

/* End Banner Header */

/* register-form */
.register-form .form-group label {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.88px;
  margin-bottom: 4px;
}

.register-form .form-group .form-control {
  padding: 12px 16px;
  border: 1px solid #ebebeb;
  border-radius: 6px;

  font-size: 15px;
  font-weight: 400;
  line-height: 21.3px;
  text-align: left;
}

.otp-inputs {
  display: flex;
  justify-content: space-between;
  gap: 8px; /* Menambahkan jarak antar input */
}

.otp-inputs input {
  flex: 1;
  width: 100%;
  height: 40px;
  text-align: center;
  font-size: 16px;
  border: 1.5px solid #e1e8ea;
  border-radius: 6px;
}

.otp-inputs input:focus,
.otp-inputs input.active {
  border-color: #1f8562;
  outline: none;
}

.error-text {
  color: red;
  margin-top: 10px;
}

.resend-otp {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.72px;
}

.resend-otp a {
  text-decoration: none;
  color: #1f8562;
}
/* end register-form */

/* modal-alert */
.modal-alert .modal-content {
  background-color: #ffffff;
  text-align: center;
  border-radius: 12px;
  border: none;
  padding: 8px;
}
.modal-alert .modal-dialog {
  width: 90vw;
  margin: auto;
}

.modal-alert .modal-body,
.modal-alert .modal-footer {
  padding: 8px;
  border: none;
}

.modal-alert .modal-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30.01px;
  text-align: center;
}

.modal-alert .modal-body {
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-size: 16px;
  font-weight: 400;
  line-height: 22.72px;
  text-align: center;
  color: #525252;
}

.modal-alert .btn-link {
  text-decoration: none;
  color: #007aff;
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  padding: 0;
}
/* End modal-alert */
